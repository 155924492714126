export const es = "es";
export const en = "en";
export const continue_ = "continue";
export const new_ = "new";
export const formStateSession = "formState";
export const email_ = "email";
export const firebase = "firebase";
export const microsoft = "microsoft";

export const texts = {
  en: {
    login: "Log In",
    loginButton: "Log In",
    signin: "Sign In",
    signinMicrosoftButton: "Sign in with Microsoft",
    navbarWelcome: "Hi!",
    navbarChangeProfile: "Change profile",
    navbarLanguageTitle: "Change language to english",
    logout: "Logout",
    newQuiz: "Start quiz",
    continueQuiz: "Continue quiz",
    modalNewQuiz:
      "By restarting this quiz, you are going to delete previous answers. Are you sure to continue?",
    yes: "YES",
    no: "NO",
    selectOptionPlaceholder: "SELECT AN OPTION",
    save: "SAVE",
    cancel: "CANCEL",
    questionsGroupsTitle: "Questions Groups",
    questionGroupsSubtitle: "Indicate the closest answer",
    votingGroupsTitle: "Voting Groups",
    votingGroupsSubtitle: "Indicates the most predominant scenario",
    questionGroupText: "Questions Group",
    votingGroupText: "Voting Group",
    formCompleted: "Form completed successfully",
    downloadResultsButtonText1: "Download IRT Report",
    downloadResultsButtonText2: "Download Draft AI in aviation",
    downloadResultsButtonText3: "Download Vueling Innovation Lab",
    scenariosTitle: "SCENARIOS",
    scenariosSubtitle:
      "Indicate the most predominant scenario in your organization",
    saveQuestionGroupButton: "Save Questions Group",
    saveVotingGroupButton: "Save Voting Group",
    of: "of",
    responseNA: "N/A",
    start: "start",
    continue: "continue",
    selectQuestionsGroupTitleStart: "Select questions group or voting group to",
    selectQuestionsGroupTitleContinue: "the quiz",
    questionsTitle: "QUESTIONS",
    questionsSubtitle: "Indicate the answer most related to your organization",
    email: "Email",
    emailPlaceholder: "Your Email",
    password: "Password",
    passwordPlaceholder: "Your Password",
    emailError: "Unauthorized user domain",
    microsoftError: "Microsoft Auth Error",
    firebaseError: "Firebase Auth Error",
  },
  es: {
    login: "Iniciar sesión",
    loginButton: "Entrar",
    signin: "Iniciar sesión",
    signinMicrosoftButton: "Entrar con Microsoft",
    navbarWelcome: "Hola!",
    navbarChangeProfile: "Cambiar perfil",
    navbarLanguageTitle: "Cambiar idioma a español",
    logout: "Cerrar sesión",
    newQuiz: "Empezar cuestionario",
    continueQuiz: "Continuar cuestionario",
    modalNewQuiz:
      "Al reiniciar este cuestionario, se eliminarán las respuestas anteriores. ¿Estás seguro de continuar?",
    yes: "SI",
    no: "NO",
    selectOptionPlaceholder: "SELECCIONA UNA OPCIÓN",
    save: "GUARDAR",
    cancel: "CANCELAR",
    questionsGroupsTitle: "Grupos de preguntas",
    questionGroupsSubtitle: "Indica la respuesta más cercana",
    votingGroupsTitle: "Grupos de votación",
    votingGroupsSubtitle: "Indica el escenario más predominante",
    questionGroupText: "Grupo de preguntas",
    votingGroupText: "Grupo de votación",
    formCompleted: "Formulario completado con éxito",
    downloadResultsButtonText1: "Descargar Informe IRT",
    downloadResultsButtonText2: "Descargar Draft AI en aviación",
    downloadResultsButtonText3: "Descargar Vueling Innovation Lab",
    scenariosTitle: "ESCENARIOS",
    scenariosSubtitle:
      "Indica el escenario más predominante en tu organización",
    saveQuestionGroupButton: "Guardar grupo de preguntas",
    saveVotingGroupButton: "Guardar grupo de votación",
    responseNA: "N/C",
    of: "de",
    start: "iniciar",
    continue: "continuar",
    selectQuestionsGroupTitleStart:
      "Selecciona un grupo de preguntas o un grupo de votación para",
    selectQuestionsGroupTitleContinue: "el cuestionario",
    questionsTitle: "PREGUNTAS",
    questionsSubtitle:
      "Indica la respuesta más relacionada con tu organización",
    email: "Email",
    emailPlaceholder: "Tu Email",
    password: "Contraseña",
    passwordPlaceholder: "Tu Contraseña",
    emailError: "Dominio de usuario no autorizado",
    microsoftError: "Error de autenticación de Microsoft",
    firebaseError: "Error de autenticación de Firebase",
  },
};
