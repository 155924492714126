import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import { useForm } from "react-hook-form";
import {
  addUserToCompanyDB,
  createUserFirestoreDB,
  getUserInfoDB,
  signIn,
} from "../lib/db";
import { useNavigate } from "react-router-dom";
import "../styles/login.scss";
import LoadingSpinner from "./loading-spinner";
import { isValidEmail } from "../lib/utils";
import { texts } from "../lib/constants";

export const LoginPassword = ({ language, changeLanguage, user, setUser }) => {
  let navigate = useNavigate();
  const { register, required, handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/home");
    }
    // eslint-disable-next-line
  }, [user]);

  const onSubmit = async () => {
    resetState();
    setIsLoading(true);
    const userLogged = await signIn(email, password);
    if (userLogged) {
      sessionStorage.setItem(
        "user_IRT",
        JSON.stringify({
          userID: userLogged.uid,
        })
      );
      getUserInfoDB(userLogged.uid).then((data) => {
        setUser(data);
      });
      createUserFirestoreDB(userLogged.uid, email);
      addUserToCompanyDB(userLogged.uid);
    } else {
      setError(true);
      setIsLoading(false);
    }
  };

  const resetState = () => {
    setUser();
    setError(false);
  };

  return (
    <div className="login-div">
      <Navbar language={language} changeLanguage={changeLanguage} />
      <div className="form-div">
        <div>
          <h2 className="form-title">{texts[language].signin}</h2>
          <hr className="form-line"></hr>
          <div className="form-inputs-div">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <p className="text-overline form-label">
                {texts[language].email}
              </p>
              <div>
                <input
                  {...register("email")}
                  id="email"
                  className="text-body-1 form-inputs"
                  type="text"
                  value={email}
                  placeholder={texts[language].emailPlaceholder}
                  onChange={(event) => {
                    resetState();
                    setEmail(event.target.value);
                  }}
                ></input>
                {user ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-success.png"
                    alt="icon"
                  ></img>
                ) : error ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-error.png"
                    alt="icon"
                  ></img>
                ) : null}
              </div>
              {user ? (
                <hr className="form-hr-success"></hr>
              ) : error ? (
                <hr className="form-hr-error"></hr>
              ) : (
                <hr className="form-hr"></hr>
              )}
              <p className="text-overline form-label">
                {texts[language].password}
              </p>
              <div>
                <input
                  {...register("password", { required })}
                  id="password"
                  className="text-body-1 form-inputs"
                  type="password"
                  value={password}
                  autoComplete="off"
                  placeholder={texts[language].passwordPlaceholder}
                  onChange={(event) => {
                    resetState();
                    setPassword(event.target.value);
                  }}
                ></input>
                {user ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-success.png"
                    alt="icon"
                  ></img>
                ) : error ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-error.png"
                    alt="icon"
                  ></img>
                ) : null}
              </div>
              {user ? (
                <hr className="form-hr-success"></hr>
              ) : error ? (
                <hr className="form-hr-error"></hr>
              ) : (
                <hr className="form-hr"></hr>
              )}
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "20px",
                }}
              >
                {isLoading && <LoadingSpinner />}
                {!isLoading && (
                  <button
                    id="btn-submit"
                    disabled={
                      email === "" || !isValidEmail(email) || password === ""
                    }
                    type="submit"
                    className="btn-login"
                  >
                    {texts[language].loginButton.toUpperCase()}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
