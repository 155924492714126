import React from "react";
import "../styles/quiz-form.scss";
import { texts } from "../lib/constants";
import { getImgUrl } from "../lib/db";

function QuizFormCompleted({ language, user }) {
  const downloadResults = async (field) => {
    switch (field) {
      case 1:
        field = process.env.REACT_APP_DOWNLOAD_MATERIAL_1;
        break;
      case 2:
        field = process.env.REACT_APP_DOWNLOAD_MATERIAL_2;
        break;
      case 3:
        field = process.env.REACT_APP_DOWNLOAD_MATERIAL_3;
        break;
      default:
        field = "";
        console.log("Default");
        break;
    }
    if (field === "") return;
    let pdfDocument = await getImgUrl(`materials/${user.id}/${field}.pdf`);
    if (pdfDocument) {
      window.open(pdfDocument);
    }
  };

  return (
    <div
      style={{
        height: "calc(100vh - 400px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h4>{texts[language].formCompleted}</h4>
      <button
        type="button"
        onClick={() => downloadResults(1)}
        style={{ width: "340px", marginTop: "36px" }}
        className="btn-1 quiz-form-question-btn-submit"
      >
        {texts[language].downloadResultsButtonText1.toUpperCase()}
      </button>
      <button
        onClick={() => downloadResults(2)}
        style={{ width: "340px", marginTop: "36px" }}
        className="btn-1 quiz-form-question-btn-submit"
      >
        {texts[language].downloadResultsButtonText2.toUpperCase()}
      </button>
      <button
        onClick={() => downloadResults(3)}
        style={{ width: "340px", marginTop: "36px" }}
        className="btn-1 quiz-form-question-btn-submit"
      >
        {texts[language].downloadResultsButtonText3.toUpperCase()}
      </button>
    </div>
  );
}

export default QuizFormCompleted;
