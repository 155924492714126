import React, { useEffect, useState } from "react";
import { getUserDataDB, saveResponsesDB } from "../lib/db";
import { changeColorQuestionResponseBtn } from "../lib/utils";

import "../styles/quiz-form-questions.scss";
import { texts } from "../lib/constants";

function QuizFormQuestions({
  language,
  user,
  currentQuestionsGroup,
  userData,
  questionsData,
  setUserData,
}) {
  const [questionsSelected, setQuestionsSelected] = useState([]);
  const [responses, setResponses] = useState([]);
  const [group, setGroup] = useState([]);

  useEffect(() => {
    let questionsDataArray = Object.entries(questionsData.questions);
    let questionTempSelected = [];
    for (
      let i = currentQuestionsGroup * 4 - 4;
      i < currentQuestionsGroup * 4;
      i++
    ) {
      questionTempSelected.push(questionsDataArray[i]);
    }
    setQuestionsSelected(questionTempSelected);
    if (currentQuestionsGroup < 10) {
      setGroup("0" + currentQuestionsGroup);
    } else {
      setGroup(currentQuestionsGroup);
    }
  }, [questionsData, currentQuestionsGroup]);

  const saveResponses = async () => {
    let type = "questions";
    await saveResponsesDB(
      user.id,
      currentQuestionsGroup,
      responses,
      type
    );
    setUserData(await getUserDataDB(user.id));
    setResponses();
    window.scrollTo(0, 0);
  };

  const saveResponse = (questionId, value) => {
    let updatedResponses = [];
    if (responses) {
      updatedResponses = [...responses];
    }

    if (updatedResponses) {
      let updatedResponsesFiltered = updatedResponses.filter(function (el) {
        return el.questionId !== questionId;
      });

      updatedResponses = [
        ...updatedResponsesFiltered,
        { questionId: questionId, value: value, timestamp: Date.now() },
      ];
    }
    setResponses(updatedResponses);
  };

  return (
    <div>
      <p className="quiz-form-title">{texts[language].questionsTitle}</p>
      <h3 className="quiz-form-subtitle">
        {texts[language].questionsSubtitle}
      </h3>
      {questionsSelected?.map((question, key) => {
        return (
          <div key={question[0]}>
            <div className="quiz-form-question-div">
              <h2 className="quiz-form-question-number">0{key + 1}.</h2>
              {question[1]?.image ? (
                <div className="quiz-form-question-img-div">
                  <img
                    className="quiz-form-question-img"
                    src={question[1].image}
                    alt="Question_Image"
                  />
                </div>
              ) : null}
              <p className="text-body-1" style={{paddingTop:"4px", paddingLeft:"8px"}}>{question[1].text}</p>
              <div className="quiz-form-question-btns">
                <button
                  id={`btn-yes-${question[0]}`}
                  onClick={() => {
                    saveResponse(question[0], 1);
                    changeColorQuestionResponseBtn(question[0], 1);
                  }}
                  className={
                    group &&
                    question[0] &&
                    userData &&
                    userData.questions &&
                    userData.questions[`G${group}`] &&
                    userData.questions[`G${group}`][question[0]] &&
                    userData.questions[`G${group}`][question[0]].value &&
                    userData.questions[`G${group}`][question[0]].value === 1
                      ? "btn-response-left btn-response-left-pressed"
                      : "btn-response-left"
                  }
                >
                  {texts[language].yes}
                </button>
                <button
                  id={`btn-no-${question[0]}`}
                  onClick={() => {
                    saveResponse(question[0], 0);
                    changeColorQuestionResponseBtn(question[0], 0);
                  }}
                  className={
                    group &&
                    question[0] &&
                    userData &&
                    userData.questions &&
                    userData.questions[`G${group}`] &&
                    userData.questions[`G${group}`][question[0]] &&
                    userData.questions[`G${group}`][question[0]].value === 0
                      ? "btn-response-center btn-response-center-pressed"
                      : "btn-response-center"
                  }
                >
                  {texts[language].no}
                </button>
                <button
                  id={`btn-na-${question[0]}`}
                  onClick={() => {
                    saveResponse(question[0], 2);
                    changeColorQuestionResponseBtn(question[0], 2);
                  }}
                  className={
                    group &&
                    question[0] &&
                    userData &&
                    userData.questions &&
                    userData.questions[`G${group}`] &&
                    userData.questions[`G${group}`][question[0]] &&
                    userData.questions[`G${group}`][question[0]].value &&
                    userData.questions[`G${group}`][question[0]].value === 2
                      ? "btn-response-right btn-response-right-pressed"
                      : "btn-response-right"
                  }
                >
                  {texts[language].responseNA}
                </button>
              </div>
            </div>
          </div>
        );
      })}
      <div className="quiz-form-question-btn-submit-div">
        <button
          onClick={() => {
            saveResponses();
          }}
          className="btn-1 quiz-form-question-btn-submit"
        >
          {texts[language].saveQuestionGroupButton.toUpperCase()}
        </button>
      </div>
    </div>
  );
}

export default QuizFormQuestions;
