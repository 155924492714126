import React, { useEffect, useState } from "react";
import "../styles/quiz.scss";
import "../styles/login.scss";
import Navbar from "./navbar";
import QuizGroups from "./quiz-groups";
import QuizForm from "./quiz-form";
import { deleteUserResponsesDB, getImgUrl, getUserDataDB } from "../lib/db";
import ModalProfile from "./modal-profile";
import { formStateSession, new_ } from "../lib/constants";

function Quiz({
  questionsData,
  language,
  changeLanguage,
  user,
  setUser,
  company,
  userProfile,
  setUserProfile,
}) {
  const [userData, setUserData] = useState();
  const [userStateForm, setUserStateForm] = useState();
  const [completedForm, setCompletedForm] = useState(false);
  const [userTypeForm, setUserTypeForm] = useState();
  const [currentQuestionsGroup, setCurrentQuestionsGroup] = useState();
  const [currentOptionsGroup, setCurrentOptionsGroup] = useState();
  const [showModalProfile, setShowModalProfile] = useState();

  useEffect(() => {
    let formState = sessionStorage.getItem(formStateSession);
    if (formState) {
      setUserStateForm(formState ? formState : new_);
    }
    if (formState === new_) {
      deleteUserResponses();
      setUserData({});
    } else {
      getUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (questionsData) {
      getImgsUrl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questionsData]);

  useEffect(() => {
    if (userData && userData.questions && userData.options && questionsData) {
      let questionsCompleted = [];
      let optionsCompleted = [];

      let totalOptions = Object.keys(questionsData.options).length / 4;
      let totalQuestions = 10;
      // eslint-disable-next-line array-callback-return
      Object.values(userData?.questions).map((question, key) => {
        if (question.done) {
          questionsCompleted.push(key);
        }
      });
      // eslint-disable-next-line array-callback-return
      Object.values(userData?.options).map((option, key) => {
        if (option.done) {
          optionsCompleted.push(key);
        }
      });
      if (
        questionsCompleted.length === totalQuestions &&
        optionsCompleted.length === totalOptions
      ) {
        setCompletedForm(true);
      } else {
        setCompletedForm(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData, questionsData]);

  const deleteUserResponses = async () => {
    if (user) {
      await deleteUserResponsesDB(user.id);
    }
  };

  const getUserData = async () => {
    if (user) {
      setUserData(await getUserDataDB(user.id));
    }
  };

  const getImgsUrl = async () => {
    Object.values(questionsData.questions).map(async (question) => {
      if (question.image) {
        question.image = await getImgUrl(question.image);
      }
    });
  };

  return (
    <div className="quiz-div">
      {user && (
        <Navbar
          setUser={setUser}
          language={language}
          changeLanguage={changeLanguage}
          company={company}
          user={user}
          setShowModalProfile={setShowModalProfile}
          setUserProfile={setUserProfile}
        />
      )}
      {showModalProfile && (
        <ModalProfile
          language={language}
          user={user}
          setUserProfileProp={setUserProfile}
          userProfileProp={userProfile}
          setShowModalProfile={setShowModalProfile}
        />
      )}
      <div className="quiz-body">
        <div className="quiz-groups-div">
          <QuizGroups
            language={language}
            userDataProp={userData}
            userStateForm={userStateForm}
            questionsData={questionsData}
            setCurrentQuestionsGroup={setCurrentQuestionsGroup}
            setCurrentOptionsGroup={setCurrentOptionsGroup}
            setUserTypeForm={setUserTypeForm}
          />
        </div>
        <div className="quiz-questions-div">
          <QuizForm
            language={language}
            user={user}
            userData={userData}
            userStateForm={userStateForm}
            questionsData={questionsData}
            currentQuestionsGroup={currentQuestionsGroup}
            currentOptionsGroup={currentOptionsGroup}
            userTypeForm={userTypeForm}
            setUserData={setUserData}
            completedForm={completedForm}
          />
        </div>
      </div>
    </div>
  );
}

export default Quiz;
