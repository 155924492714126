import React from "react";

import "../styles/quiz-form.scss";
import QuizFormOptions from "./quiz-form-options";
import QuizFormQuestions from "./quiz-form-questions";
import QuizFormCompleted from "./quiz-form-completed";
import QuizFormInitial from "./quiz-form-initial";

function QuizForm({
  language,
  user,
  userStateForm,
  currentQuestionsGroup,
  currentOptionsGroup,
  userTypeForm,
  questionsData,
  userData,
  setUserData,
  completedForm,
}) {
  return (
    <div>
      <div className="hr-div">
        <hr />
      </div>
      <div className="quiz-form-div">
        {userTypeForm === "questions" && !completedForm ? (
          <QuizFormQuestions
            language={language}
            user={user}
            userData={userData}
            questionsData={questionsData}
            currentQuestionsGroup={currentQuestionsGroup}
            setUserData={setUserData}
          />
        ) : userTypeForm === "options" && !completedForm ? (
          <QuizFormOptions
            language={language}
            user={user}
            userData={userData}
            questionsData={questionsData}
            currentQuestionsGroup={currentQuestionsGroup}
            currentOptionsGroup={currentOptionsGroup}
            userTypeForm={userTypeForm}
            setUserData={setUserData}
          />
        ) : !completedForm ? (
          <QuizFormInitial language={language} userStateForm={userStateForm} />
        ) : (
          <QuizFormCompleted language={language} user={user} />
        )}
      </div>
    </div>
  );
}

export default QuizForm;
