import React from "react";

import "../styles/quiz-form.scss";
import { new_, texts } from "../lib/constants";

function QuizFormInitial({ language, userStateForm }) {
  return (
    <h4>
      {texts[language].selectQuestionsGroupTitleStart}{" "}
      {userStateForm === new_
        ? texts[language].start
        : texts[language].continue}{" "}
      {texts[language].selectQuestionsGroupTitleContinue}
    </h4>
  );
}

export default QuizFormInitial;
