export const changeColorQuestionResponseBtn = (questionId, value) => {
  switch (value) {
    case 1:
      document
        .getElementById("btn-yes-" + questionId)
        .classList.add("btn-response-left-pressed");
      document
        .getElementById("btn-no-" + questionId)
        .classList.remove("btn-response-center-pressed");
      document
        .getElementById("btn-na-" + questionId)
        .classList.remove("btn-response-right-pressed");
      break;
    case 2:
      document
        .getElementById("btn-yes-" + questionId)
        .classList.remove("btn-response-left-pressed");
      document
        .getElementById("btn-no-" + questionId)
        .classList.remove("btn-response-center-pressed");
      document
        .getElementById("btn-na-" + questionId)
        .classList.add("btn-response-right-pressed");
      break;
    case 0:
      document
        .getElementById("btn-yes-" + questionId)
        .classList.remove("btn-response-left-pressed");
      document
        .getElementById("btn-no-" + questionId)
        .classList.add("btn-response-center-pressed");
      document
        .getElementById("btn-na-" + questionId)
        .classList.remove("btn-response-right-pressed");
      break;
    default:
      document
        .getElementById("btn-yes-" + questionId)
        .classList.remove("btn-response-left-pressed");
      document
        .getElementById("btn-no-" + questionId)
        .classList.remove("btn-response-center-pressed");
      document
        .getElementById("btn-na-" + questionId)
        .classList.remove("btn-response-right-pressed");
      break;
  }
};
export const changeColorOptionResponseDiv = (questionId, value) => {
  switch (value) {
    case 1:
      document
        .getElementById("div-op-1-" + questionId)
        .classList.add("quiz-form-options-radio-input-item-pressed");
      document
        .getElementById("div-op-2-" + questionId)
        .classList.remove("quiz-form-options-radio-input-item-pressed");
      document
        .getElementById("div-op-na-" + questionId)
        .classList.remove("quiz-form-options-radio-input-item-pressed");
      break;
    case 2:
      document
        .getElementById("div-op-1-" + questionId)
        .classList.remove("quiz-form-options-radio-input-item-pressed");
      document
        .getElementById("div-op-2-" + questionId)
        .classList.remove("quiz-form-options-radio-input-item-pressed");
      document
        .getElementById("div-op-na-" + questionId)
        .classList.add("quiz-form-options-radio-input-item-pressed");
      break;
    case 0:
      document
        .getElementById("div-op-1-" + questionId)
        .classList.remove("quiz-form-options-radio-input-item-pressed");
      document
        .getElementById("div-op-2-" + questionId)
        .classList.add("quiz-form-options-radio-input-item-pressed");
      document
        .getElementById("div-op-na-" + questionId)
        .classList.remove("quiz-form-options-radio-input-item-pressed");
      break;
    default:
      document
        .getElementById("div-op-1-" + questionId)
        .classList.remove("quiz-form-options-radio-input-item-pressed");
      document
        .getElementById("div-op-2-" + questionId)
        .classList.remove("quiz-form-options-radio-input-item-pressed");
      document
        .getElementById("div-op-na-" + questionId)
        .classList.remove("quiz-form-options-radio-input-item-pressed");
      break;
  }
};

export const isValidEmail = (email) => {
  if (!email) return false;
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
};
