import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../styles/login.scss";
import "../styles/buttons.scss";
import Navbar from "./navbar";
import ModalNewQuiz from "./modal-new-quiz";
import { continue_, formStateSession, texts } from "../lib/constants";
import ModalProfile from "./modal-profile";

function Home({
  changeLanguage,
  language,
  user,
  setUser,
  company,
  userProfile,
  setUserProfile,
}) {
  const [showModalStartQuiz, setShowModalStartQuiz] = useState();
  const [showModalProfile, setShowModalProfile] = useState();
  let navigate = useNavigate();

  useEffect(() => {
    if (user && !userProfile) {
      setShowModalProfile(true);
    } else {
      setShowModalProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userProfile]);

  const goToQuiz = (formState) => {
    sessionStorage.setItem(formStateSession, formState);
    navigate("/quiz");
  };

  return (
    <div className="home-div">
      {showModalStartQuiz && (
        <ModalNewQuiz
          language={language}
          goToQuiz={goToQuiz}
          setShowModalStartQuiz={setShowModalStartQuiz}
        />
      )}
      {showModalProfile && (
        <ModalProfile
          language={language}
          user={user}
          setShowModalProfile={setShowModalProfile}
          setUserProfileProp={setUserProfile}
          userProfileProp={userProfile}
        />
      )}

      <Navbar
        changeLanguage={changeLanguage}
        language={language}
        company={company}
        user={user}
        setUser={setUser}
        setShowModalProfile={setShowModalProfile}
        setUserProfile={setUserProfile}
      />

      <div className="form-div">
        <img src="/assets/img/logo-home.png" alt="home" />
        <div
          style={{
            display: "flex",
            marginTop: "80px",
          }}
        >
          <button
            onClick={() => {
              setShowModalStartQuiz(true);
            }}
            className="btn-1 btn-options-quiz"
          >
            {texts[language].newQuiz.toUpperCase()}
          </button>
          <button
            onClick={() => {
              goToQuiz(continue_);
            }}
            className="btn-2 btn-options-quiz"
          >
            {texts[language].continueQuiz.toUpperCase()}
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
