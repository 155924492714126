import React, { useEffect, useState } from "react";
import { getProfileQuestionsRealtime, updateUserProfileDB } from "../lib/db";
import "../styles/modal-select-profile.scss";
import LoadingSpinner from "./loading-spinner";
import { en, texts } from "../lib/constants";

const ModalProfile = ({
  language,
  user,
  setShowModalProfile,
  userProfileProp,
  setUserProfileProp,
}) => {
  const [profileQuestions, setProfileQuestions] = useState();
  const [userProfile, setUserProfile] = useState(userProfileProp);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    getProfileQuestionsRealtime().then((data) => {
      setProfileQuestions(data);
      setIsLoading(false);
    });
  }, []);

  const getUserProfile = (event) => {
    setUserProfile({ ...userProfile, [event.target.name]: event.target.value });
  };

  const updateUserProfile = async () => {
    if (userProfile) {
      await updateUserProfileDB(user.id, userProfile);
      if (setUserProfileProp) {
        setUserProfileProp(userProfile);
      }
      setShowModalProfile();
    }
  };

  return !isLoading ? (
    <div className="modal">
      <div className="modal-select-main">
        <div
          style={{
            display: "flex",
            textAlign: "left",
            alignItems: "center",
            marginTop: "8px",
            marginLeft: "12px",
          }}
        >
          <p>
            {language === en
              ? user?.name + " profile"
              : "Perfil de " + user?.name}
          </p>
        </div>
        {profileQuestions &&
          profileQuestions.length > 0 &&
          profileQuestions.map((question, index) => {
            return (
              <div key={index}>
                <p style={{ margin: "12px 0px" }} className="modal-text">
                  {language === en
                    ? question.question.toUpperCase()
                    : question.question_es.toUpperCase()}
                </p>
                <div className="modal-select-div">
                  <select
                    name={question.question}
                    defaultValue={
                      userProfile
                        ? userProfile[question.question]
                        : question.options[0]
                    }
                    onChange={getUserProfile}
                    className="modal-select"
                  >
                    <option
                      style={{ fontSize: "16px" }}
                      value="0"
                      disabled
                      selected
                    >
                      {texts[language].selectOptionPlaceholder}
                    </option>
                    {Object.values(question.options).map((option) => {
                      return (
                        //option is object with 2 languages: en and es, then we need to get the language selected and show option in that language
                        <option key={option[language]} value={option[en]}>
                          {option[language]}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
            );
          })}

        <div className="modal-select-btns-div">
          <button
            disabled={!userProfile || userProfile === ""}
            onClick={updateUserProfile}
            className="btn-1 btn-options-quiz"
          >
            {texts[language].save}
          </button>

          {userProfileProp && (
            <button
              onClick={() => setShowModalProfile()}
              className="btn-2 btn-options-quiz"
            >
              {texts[language].cancel}
            </button>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        zIndex: "1000",
      }}
    >
      <LoadingSpinner />
    </div>
  );
};

export default ModalProfile;
