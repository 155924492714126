import React from "react";
import "../styles/modal-new-quiz.scss";
import { new_, texts } from "../lib/constants";
const ModalNewQuiz = ({ language, goToQuiz, setShowModalStartQuiz }) => {
  return (
    <div className="modal">
      <div className="modal-main">
        <p className="modal-text">{texts[language].modalNewQuiz}</p>
        <div className="modal-btns-div" style={{ marginTop: "30px" }}>
          <button
            onClick={() => {
              goToQuiz(new_);
              setShowModalStartQuiz();
            }}
            className="btn-1 btn-options-quiz"
          >
            {texts[language].yes}
          </button>
          <button
            onClick={() => {
              setShowModalStartQuiz();
            }}
            className="btn-2 btn-options-quiz"
          >
            {texts[language].no}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalNewQuiz;
