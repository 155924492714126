import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import {
  addDataMicrosoftRealtime,
  addUserMicrosoftToCompanyFirestoreDB,
  createUserFirestoreDB,
  getUserInfoDB,
} from "../lib/db";
import { useNavigate } from "react-router-dom";
import "../styles/login.scss";
import LoadingSpinner from "./loading-spinner";

import { getAuth, OAuthProvider, signInWithPopup } from "firebase/auth";
import { email_, firebase, microsoft, texts } from "../lib/constants";
import ModalError from "./modal-error";

export const LoginMicrosoft = ({ language, changeLanguage, user, setUser }) => {
  let navigate = useNavigate();
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [showModalError, setShowModalError] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const filterDomain = process.env.REACT_APP_MICROSOFT_LOGIN_EMAIL_DOMAIN;
  const filterDomain2 = process.env.REACT_APP_MICROSOFT_LOGIN_EMAIL_DOMAIN_2;

  useEffect(() => {
    if (user && email) {
      const filterEmail = email.split("@")[1];
      if (filterEmail === filterDomain || filterEmail === filterDomain2) {
        navigate("/home");
      } else {
        setShowModalError(true);
        setError(email_);
        setIsLoading(false);
      }
    }
  }, [user, email]); // eslint-disable-line

  const handleMicrosoftLogin = async () => {
    setIsLoading(true);
    const provider = new OAuthProvider("microsoft.com");
    try {
      let response = await signInWithPopup(auth, provider);
      if (response) {
        getUserInfoDB(response.user.uid).then((data) => {
          setUser(data);
        });
        sessionStorage.setItem(
          "user_IRT",
          JSON.stringify({
            userID: response.user.uid,
          })
        );
        setEmail(response.user.email);
        await createUserFirestore(response);
      } else {
        console.log("Error signing in with Microsoft");
        setShowModalError(true);
        setError(microsoft);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error signing:", error);
      setShowModalError(true);
      setError(firebase);
      setIsLoading(false);
    }
  };

  const createUserFirestore = async (response) => {
    await createUserFirestoreDB(
      response.user.uid,
      response.user.providerData[0].displayName
    );
    await addUserMicrosoftToCompanyFirestoreDB(response.user.uid);
    await addDataMicrosoftRealtime(response.user.uid, response);
  };

  return (
    <div className="login-div">
      {showModalError && (
        <ModalError
          language={language}
          error={error}
          setShowModalError={setShowModalError}
        />
      )}
      <Navbar language={language} changeLanguage={changeLanguage} />
      <div className="form-div">
        <div>
          <h2 style={{ color: "white" }}>{texts[language].signin}</h2>
          <hr className="form-line"></hr>
          <div className="form-inputs-div">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              {isLoading ? (
                <LoadingSpinner />
              ) : (
                <button
                  onClick={handleMicrosoftLogin}
                  className="btn-login"
                  style={{
                    width: "100%",
                  }}
                >
                  {texts[language].signinMicrosoftButton}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
