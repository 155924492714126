import React, { useEffect, useState } from "react";
import Navbar from "./navbar";
import { useForm } from "react-hook-form";
import {
  addUserToCompanyDB,
  createUserAuth,
  createUserFirestoreDB,
  getUserInfoDB,
  loginByEmail,
} from "../lib/db";
import { useNavigate } from "react-router-dom";
import "../styles/login.scss";
import LoadingSpinner from "./loading-spinner";
import { isValidEmail } from "../lib/utils";
import { texts } from "../lib/constants";

export const LoginEmail = ({ language, changeLanguage, user, setUser }) => {
  let navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (user) {
      navigate("/home");
    }
    // eslint-disable-next-line
  }, [user]);

  const onSubmit = async () => {
    setIsLoading(true);
    resetState();
    let newUser = await createUserAuth(email);
    if (newUser) {
      await createUserFirestoreDB(newUser.uid, email);
      await addUserToCompanyDB(newUser.uid);
      await loginByEmail(email).then((user) => {
        if (user) {
          sessionStorage.setItem(
            "user_IRT",
            JSON.stringify({
              userID: user.uid,
            })
          );
          getUserInfoDB(user.uid).then((data) => {
            setUser(data);
          });
        }
      });
    } else {
      //user already exists
      let userLogged = await loginByEmail(email);
      if (userLogged) {
        sessionStorage.setItem(
          "user_IRT",
          JSON.stringify({
            userID: userLogged.uid,
          })
        );
        getUserInfoDB(userLogged.uid).then((data) => {
          setUser(data);
        });
      } else {
        setIsLoading(false);
        setError(true);
      }
    }
    setIsLoading(false);
  };

  const resetState = () => {
    setUser();
    setError(false);
  };

  return (
    <div className="login-div">
      <Navbar
        language={language}
        changeLanguage={changeLanguage}
        login={true}
      />
      <div className="form-div">
        <div>
          <h2 className="form-title">{texts[language].login.toUpperCase()}</h2>
          <hr className="form-line"></hr>
          <div className="form-inputs-div">
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
              <p className="text-overline form-label">
                {texts[language].email}
              </p>
              <div>
                <input
                  {...register("email")}
                  id="email"
                  className="text-body-1 form-inputs"
                  type="text"
                  value={email}
                  placeholder={texts[language].emailPlaceholder}
                  onChange={(event) => {
                    resetState();
                    setEmail(event.target.value);
                  }}
                ></input>
                {user ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-success.png"
                    alt="icon"
                  ></img>
                ) : error ? (
                  <img
                    className="form-icon"
                    src="/assets/img/icon-error.png"
                    alt="icon"
                  ></img>
                ) : null}
              </div>
              {user ? (
                <hr className="form-hr-success"></hr>
              ) : error ? (
                <hr className="form-hr-error"></hr>
              ) : (
                <hr className="form-hr"></hr>
              )}

              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginTop: "20px",
                }}
              >
                {isLoading && <LoadingSpinner />}
                {!isLoading && (
                  <button
                    id="btn-submit"
                    disabled={email === "" || !isValidEmail(email)}
                    type="submit"
                    className="btn-login"
                  >
                    {texts[language].loginButton.toUpperCase()}
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
