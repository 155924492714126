import React, { useEffect, useState } from "react";
import "../styles/modal-new-quiz.scss";
import { email_, firebase, texts } from "../lib/constants";
const ModalError = ({ setShowModalError, error, language }) => {
  const [info, setInfo] = useState("");
  useEffect(() => {
    if (error === email_) {
      setInfo(texts[language].emailError);
    } else if (error === firebase) {
      setInfo(texts[language].firebaseError);
    } else {
      setInfo(texts[language].microsoftError);
    }
  }, [language, error]);
  return (
    <div className="modal">
      <div className="modal-main" style={{ width: "380px" }}>
        <p className="modal-text">{info}</p>
        <div className="modal-btns-div">
          <button
            style={{ width: "140px", marginTop: "18px" }}
            onClick={() => {
              setShowModalError();
            }}
            className="btn-1 btn-options-quiz"
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModalError;
