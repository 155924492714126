import React from "react";
import "../styles/navbar.scss";
import { getAuth, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { Language } from "./language";
import { texts } from "../lib/constants";
function Navbar({
  user,
  setUser,
  company,
  setShowModalProfile,
  language,
  changeLanguage,
  setUserProfile,
}) {
  const navigate = useNavigate();
  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        sessionStorage.clear();
        setUser();
        setUserProfile();
        navigate("/");
      })
      .catch((error) => {
        console.error("Error signout user:", error);
      });
  };

  return (
    <div className="navbar-div">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          onClick={() => user && navigate("/home")}
          style={{ cursor: user ? "pointer" : "default" }}
          className="img-logo"
          src="/assets/img/logo-navbar.png"
          alt="logo"
        />
        {process.env.REACT_APP_LOGO_NAVBAR_COMPANY_IMAGE &&
          process.env.REACT_APP_LOGO_NAVBAR_COMPANY_IMAGE !== "" && (
            <img
              className="img-company"
              src="/assets/img/company.png"
              alt="company"
            />
          )}
      </div>
      {!user && (
        <div style={{ position: "absolute", right: "36px", top: "36x" }}>
          <Language language={language} changeLanguage={changeLanguage} />
        </div>
      )}
      <div>
        {user ? (
          <div className="user-info-div">
            <div className="user-info">
              <p className="text-body-3">
                {texts[language].navbarWelcome} {user?.name} · {company?.name}
              </p>
              <p onClick={() => setShowModalProfile(true)} className="links">
                {texts[language].navbarChangeProfile}
              </p>
              <p className="links" onClick={logout}>
                {texts[language].logout}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src="/assets/img/icon-profile.png" alt="login" />
              <Language
                userID={user.id}
                language={language}
                changeLanguage={changeLanguage}
              />
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

export default Navbar;
