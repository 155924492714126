import React from "react";
import { en, es, texts } from "../lib/constants";

export const Language = ({ userID, language, changeLanguage }) => {
  return (
    <div
      style={{
        fontWeight: "bold",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "2px",
      }}
    >
      {language === es ? (
        <p
          title={texts[en].navbarLanguageTitle}
          onClick={() => changeLanguage(userID, en)}
          style={{ cursor: "pointer" }}
        >
          {en.toUpperCase()}
        </p>
      ) : (
        <p
          title={texts[es].navbarLanguageTitle}
          style={{ cursor: "pointer" }}
          onClick={() => changeLanguage(userID, es)}
        >
          {es.toUpperCase()}
        </p>
      )}
    </div>
  );
};
