import firebaseConfig from "./firebase";
import "firebase/compat/database";
import { initializeApp } from "@firebase/app";

import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  OAuthProvider,
  signInWithRedirect,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { child, get, getDatabase, ref, update } from "firebase/database";
import {
  arrayUnion,
  doc,
  getDoc,
  getFirestore,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref as refStorage,
  getDownloadURL,
} from "firebase/storage";

initializeApp(firebaseConfig);
const db = getDatabase();
const dbFirestore = getFirestore();
const auth = getAuth();
const storage = getStorage();
const company = process.env.REACT_APP_COMPANY;

export const loginByEmail = async (email) => {
  const password = process.env.REACT_APP_EMAIL_LOGIN_PASSWORD;
  return await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};

export const addUserToCompanyDB = async (userID) => {
  const companyRef = doc(dbFirestore, `companies/${company}`);
  //get company data
  const companyData = await getDoc(companyRef);

  //check if userID is in array users in company doc
  if (companyData.data().users.includes(userID)) {
    return;
  }
  //get the number of users
  const totalUsers = companyData.data().totalUsers;
  await updateDoc(companyRef, {
    users: arrayUnion(userID),
    //add 1 to the number of users
    totalUsers: totalUsers + 1,
  });
};

export const createUserAuth = async (email, password) => {
  if (!password) {
    password = process.env.REACT_APP_EMAIL_LOGIN_PASSWORD;
  }
  return await createUserWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};

export const signIn = async (email, password) => {
  return await signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      return user;
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode, errorMessage);
    });
};

export const getUserDataDB = async (userID) => {
  const dbRef = ref(getDatabase());
  return await get(child(dbRef, "usersData/" + userID + "/" + company))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
    });

  // let data;
  // const userDataRef = ref(db, "usersData/" + userID);
  // onValue(userDataRef, (snapshot) => {
  //   data = snapshot.val();
  //   return data;
  // });
  // if (data) {
  //   return data;
  // } else {
  //   return {};
  // }

  // let userData;
  // onValue(
  //   ref(db, "/usersData/" + userID),
  //   (snapshot) => {
  //     userData = snapshot.val();
  //   },
  //   {
  //     onlyOnce: false,
  //   }
  // );
  // if (userData) {
  //   return userData;
  // } else {
  //   return {};
  // }
};

export const getUserInfoDB = async (userID) => {
  const collectionRef = doc(dbFirestore, `users/${userID}`);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return { name: "", company: "" };
  }
};

export const getUserProfileDB = async (userID) => {
  const dbRef = ref(getDatabase());
  return await get(
    child(dbRef, "usersData/" + userID + "/" + company + "/profile")
  )
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const updateUserProfileDB = async (userID, userProfile) => {
  await update(ref(db, `usersData/${userID}/${company}`), {
    profile: userProfile,
  });
};

export const getCompanyInfoDB = async () => {
  const collectionRef = doc(dbFirestore, `companies/${company}`);
  const docSnap = await getDoc(collectionRef);
  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return { id: "", name: "" };
  }
};

export const getQuestionsDataDB = async (language) => {
  const dbRef = ref(getDatabase());
  return await get(child(dbRef, "questionsData/" + company + "_" + language))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const saveResponsesDB = async (
  userID,
  questionsGroup,
  responses,
  type
) => {
  if (responses) {
    if (questionsGroup < 10) {
      questionsGroup = "0" + questionsGroup;
    }
    responses.map(async (response) => {
      await update(
        ref(
          db,
          `usersData/${userID}/${company}/${type}/G${questionsGroup}/${response.questionId}`
        ),
        {
          timestamp: response.timestamp,
          value: response.value,
          done: true,
        }
      );
    });

    const dbRef = ref(getDatabase());
    const numResponses = await get(
      child(dbRef, `usersData/${userID}/${company}/${type}/G${questionsGroup}/`)
    )
      .then(async (snapshot) => {
        if (snapshot.exists()) {
          return await snapshot.val();
        } else {
          return {};
        }
      })
      .catch((error) => {
        console.error(error);
      });
    if (Object.keys(numResponses).length >= 4) {
      await update(
        ref(db, `usersData/${userID}/${company}/${type}/G${questionsGroup}/`),
        {
          done: true,
        }
      );
    }
  }
};

export const deleteUserResponsesDB = async (userID) => {
  await update(ref(db, `usersData/${userID}/${company}`), {
    questions: {},
    options: {},
  });
};

export const getImgUrl = async (img) => {
  return await getDownloadURL(refStorage(storage, img))
    .then((url) => {
      return url;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const handleMicrosoftLoginDB = async () => {
  // setLoader(prevState => ({...prevState, microsoftLoading: true}));
  const provider = new OAuthProvider("microsoft.com");
  return await signInWithPopup(auth, provider)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      console.log(error);
    });
};

export const handleMicrosoftDB = async () => {
  // setLoader(prevState => ({...prevState, microsoftLoading: true}));
  const provider = new OAuthProvider("microsoft.com");
  return await signInWithRedirect(auth, provider);
};

export const createUserFirestoreDB = async (userID, userName) => {
  await setDoc(doc(dbFirestore, "users", userID), {
    name: userName,
    id: userID,
    company: company,
  });
};

export const addUserMicrosoftToCompanyFirestoreDB = async (userID) => {
  const companyRef = doc(dbFirestore, `companies/${company}`);
  await updateDoc(companyRef, {
    users: arrayUnion(userID),
  });
};
export const addDataMicrosoftRealtime = async (userID, data) => {
  await update(ref(db, `Microsoft/${userID}/`), {
    operationType: data.operationType,
    providerId: data.providerId,
    _tokenResponse: data._tokenResponse,
    user: {
      accessToken: data.user.accessToken,
      auth: {
        app: data.user.auth.app,
        config: data.user.auth.config,
        lastNotifiedUid: data.user.auth.lastNotifiedUid,
        _errorFactory: data.user.auth._errorFactory,
      },
      displayName: data.user.displayName,
      email: data.user.email,
      metadata: data.user.metadata,
      phoneNumber: data.user.phoneNumber,
      photoURL: data.user.photoURL,
      providerData: data.user.providerData,
      providerId: data.user.providerId,
      reloadListener: data.user.reloadListener,
      reloadUserInfo: data.user.reloadUserInfo,
      stsTokenManager: data.user.stsTokenManager,
      tenantId: data.user.tenantId,
      uid: data.user.uid,
    },
  });
};

export const getProfileQuestionsRealtime = async () => {
  const dbRef = ref(getDatabase());
  return await get(child(dbRef, "profiles/"))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return {};
      }
    })
    .catch((error) => {
      console.error(error);
    });
};

export const changeUserLanguageRealtime = async (userID, lang) => {
  const dbRef = ref(getDatabase());
  await update(child(dbRef, `usersData/${userID}/${company}/`), {
    language: lang,
  });
};

export const getUserLanguageRealtime = async (userID) => {
  const dbRef = ref(getDatabase());
  return await get(child(dbRef, `usersData/${userID}/${company}/language`))
    .then(async (snapshot) => {
      if (snapshot.exists()) {
        return await snapshot.val();
      } else {
        return "en";
      }
    })
    .catch((error) => {
      console.error(error);
    });
};
