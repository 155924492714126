import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/app.scss";
import { LoginMicrosoft } from "./components/login-microsoft";
import Home from "./components/home";
import Quiz from "./components/quiz";
import { LoginEmail } from "./components/login-email";
import { LoginPassword } from "./components/login-password";
import { useEffect, useState } from "react";
import {
  changeUserLanguageRealtime,
  getCompanyInfoDB,
  getQuestionsDataDB,
  getUserInfoDB,
  getUserLanguageRealtime,
  getUserProfileDB,
} from "./lib/db";
import { en } from "./lib/constants";

function App() {
  const loginType = process.env.REACT_APP_LOGIN_TYPE;
  const [language, setLanguage] = useState(en);
  const [questionsData, setQuestionsData] = useState();
  const [user, setUser] = useState(null);
  const [company, setCompany] = useState(null);
  const [userProfile, setUserProfile] = useState();

  useEffect(() => {
    let userSession = JSON.parse(sessionStorage.getItem("user_IRT"));
    if (userSession) {
      getUserInfoDB(userSession.userID).then((data) => {
        setUser(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user) {
      getUserProfileDB(user.id).then((data) => {
        if (Object.values(data).length > 0) {
          setUserProfile(data);
        }
      });
      getUserLanguageRealtime(user.id).then((userLanguage) => {
        if (userLanguage === null) {
          userLanguage = language;
        }
        changeLanguage(user.id, userLanguage);
      });
      getQuestionsDataDB(language).then((data) => {
        setQuestionsData(data);
      });
      getCompanyInfoDB(user.company).then((data) => {
        setCompany(data);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, language]);

  const changeLanguage = async (userID, lang) => {
    setLanguage(lang);
    if (userID) {
      await changeUserLanguageRealtime(userID, lang);
    }
  };

  const loginComponents = {
    microsoft: (
      <LoginMicrosoft
        language={language}
        changeLanguage={changeLanguage}
        user={user}
        setUser={setUser}
      />
    ),
    email: (
      <LoginEmail
        language={language}
        changeLanguage={changeLanguage}
        user={user}
        setUser={setUser}
      />
    ),
    password: (
      <LoginPassword
        language={language}
        changeLanguage={changeLanguage}
        user={user}
        setUser={setUser}
      />
    ),
  };

  return (
    <div>
      {loginType && (
        <BrowserRouter>
          <Routes>
            <Route index element={loginComponents[loginType]} />
            <Route path="/" element={loginComponents[loginType]} />
            <Route path="/login" element={loginComponents[loginType]} />
            <Route
              path="/home"
              element={
                <Home
                  user={user}
                  setUser={setUser}
                  userProfile={userProfile}
                  setUserProfile={setUserProfile}
                  company={company}
                  changeLanguage={changeLanguage}
                  language={language}
                />
              }
            />
            <Route
              path="/quiz"
              element={
                <Quiz
                  user={user}
                  setUser={setUser}
                  userProfile={userProfile}
                  setUserProfile={setUserProfile}
                  company={company}
                  changeLanguage={changeLanguage}
                  language={language}
                  questionsData={questionsData}
                />
              }
            />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
