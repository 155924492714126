import React, { useEffect, useState } from "react";

import "../styles/quiz-groups.scss";
import QuizGroupsOptions from "./quiz-groups-options";
import QuizGroupsQuestions from "./quiz-groups-questions";
import { en, texts } from "../lib/constants";

function QuizGroups({
  language,
  questionsData,
  userDataProp,
  setCurrentQuestionsGroup,
  setCurrentOptionsGroup,
  setUserTypeForm,
}) {
  const [questionsDataArray, setQuestionsDataArray] = useState();
  const [options, setOptions] = useState();
  const [questions, setQuestions] = useState();
  const [userData, setUserData] = useState(userDataProp);
  const [isActiveQuestionsGroups, setIsActiveQuestionsGroups] = useState();
  const [isActiveVotingGroups, setIsActiveVotingGroups] = useState();

  useEffect(() => {
    setUserData(userDataProp);
  }, [userDataProp]);

  useEffect(() => {
    if (questionsData) {
      setQuestionsDataArray(Object.entries(questionsData));
    }
  }, [questionsData]);

  useEffect(() => {
    if (questionsDataArray) {
      questionsDataArray.map((question) => {
        if (question[0] === "questions") {
          return setQuestions(Object.values(question[1]));
        } else {
          return setOptions(Object.values(question[1]));
        }
      });
    }
  }, [questionsDataArray]);

  return (
    <div className="groups-div">
      <div className="groups-title-div">
        <h4 style={{fontSize:language === en ? "34px" : "30px"}} className="groups-title">{texts[language].questionsGroupsTitle}</h4>
      </div>
      <div>
        <p className="subtitle text-subtitle-2">
          {texts[language].questionGroupsSubtitle}
        </p>
      </div>
      <QuizGroupsQuestions
        language={language}
        userData={userData}
        questions={questions}
        setCurrentQuestionsGroup={setCurrentQuestionsGroup}
        setUserTypeForm={setUserTypeForm}
        isActiveQuestionsGroups={isActiveQuestionsGroups}
        setIsActiveQuestionsGroups={setIsActiveQuestionsGroups}
        setIsActiveVotingGroups={setIsActiveVotingGroups}
      />
      <div className="groups-title-div">
        <h4 style={{fontSize:language === en ? "34px" : "30px"}} className="groups-title">{texts[language].votingGroupsTitle}</h4>
      </div>
      <div>
        <p className="subtitle text-subtitle-2">
          {texts[language].votingGroupsSubtitle}
        </p>
      </div>
      <QuizGroupsOptions
        language={language}
        userData={userData}
        options={options}
        setCurrentOptionsGroup={setCurrentOptionsGroup}
        setUserTypeForm={setUserTypeForm}
        isActiveVotingGroups={isActiveVotingGroups}
        setIsActiveQuestionsGroups={setIsActiveQuestionsGroups}
        setIsActiveVotingGroups={setIsActiveVotingGroups}
      />
    </div>
  );
}

export default QuizGroups;
